export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard',
    meta:{
      hideBackButton: true,
    },
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard-v2',
    meta:{
      hideBackButton: true,
    },
    name: 'dashboard-ecommerce-v2',
    component: () => import('@/views/dashboard/ecommerce-v2/Ecommerce.vue'),
  },
]
